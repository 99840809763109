import 'bootstrap/dist/js/bootstrap.min.js';

import $ from "jquery";
import './src/scss/app.scss';

export const onRouteUpdate = ({location}) => {

    function onWindowScroll() {
        if ($(this).scrollTop() > 60) {
            $('.navbar').addClass('affix');
        } else {
            $('.navbar').removeClass('affix');
        }
    }

    /*function onLandingPageMenuClick(event) {
        const $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - 60
        }, 600, 'easeInOutExpo');
        event.preventDefault();
    }*/

    function onMenuCollapseToggling() {
        if (!$(this).parent().hasClass('dropdown'))
            $(".navbar-collapse").collapse('hide');
    }

    $(document).ready(function () {

        if(location.pathname === '/temoignages' || location.pathname === '/temoignages/' || location.pathname === '/') {
            $('.client-testimonial').owlCarousel({
                loop: false,
                margin: 30,
                nav: false,
                responsiveClass: true,
                autoplay: false,
                autoplayHoverPause: false,
                lazyLoad: true,
                responsive: {
                    0: {
                        items: 1,
                        dot: true,
                    },
                    600: {
                        items: 2,
                        dot: true,
                    },
                    1000: {
                        items: 2,
                        dot: true,
                    }
                }
            });
        }

        if (location.pathname !== '/') {
            /*$(window).off('scroll');
            $(document).off('click', 'a.page-scroll', onLandingPageMenuClick);
            $(".navbar-nav li a").off("click", onMenuCollapseToggling);*/
            return;
        }

        $(window).on('scroll', onWindowScroll);
        // $(document).on('click', 'a.page-scroll', onLandingPageMenuClick);
        $(".navbar-nav li a").on("click", onMenuCollapseToggling);

        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
    });
};